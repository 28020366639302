.btn-none {
    background-color: transparent;
    border: initial;
    text-decoration: none;
}

button:focus {
    outline: none;
}

.btn.focus, .btn:focus {
    outline: 0;
    box-shadow: initial;
}



