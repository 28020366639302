/* PAGES */

html {
    scroll-behavior: smooth;
}

.burger-open {
    height: 100vh;
    overflow: hidden;
}

::placeholder {
    color: inherit;
    opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: inherit;
}
::-ms-input-placeholder { /* Microsoft Edge */
    color:inherit;
}


