body {
    @media screen and (min-width: 992px) and (max-width: 1650px) {
        margin-left: 50px;
        margin-right: 50px;
    }
}


.navbar {
    padding: 0.7rem 1rem;
}

hr {
    border-bottom: 1px solid #a4abb1;
    margin: 0;
    border-top: initial;
    &.hr-black {
        border-bottom: 1px solid #223654;
    }
    &.double-hr {
        border-top: 2px solid #48555f;
        border-bottom: 1px solid #48555f;
        height: 3px;
        background-color: #fff;
    }
}

.ic-btn {
    background-color: $col-orange;
    color: #fff;
    border: 2px solid $col-orange;
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 4px;
    padding: 0.7rem 1.2rem;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.5px;
}

.ic-btn-white {
    background-color: #fff;
    color: $col-orange;
    border: 1px solid $col-orange;
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 4px;
    padding: 0.7rem 1.2rem;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.5px;
}

.ic-btn-white:hover {
    color: #fff;
    background-color: $col-orange;
}

.ic-btn:hover {
    color: #fff;
}

.ic-outline-grey-btn {
    &.ic-btn {
        border: 2px solid #898989;
        background-color: white;
        color: #898989;
    }
    &.ic-btn:hover {
        color: #fff;
        background-color: #898989;
    }
}

.ic-outline-grey-btn-alt {
    &.ic-btn, &.ic-btn:hover  {
        border: 2px solid #898989;
        background-color: white;
        color: #898989;
    }

}

.ic-outline-orange-status {
    border: 2px solid $col-orange;
    background-color: white;
    color: $col-orange;
}

.ic-outline-grey-status {
    border: 2px solid #898989;
    background-color: white;
    color: #898989;
}

.ic-outline-black-status {
    border: 2px solid $col-black;
    background-color: white;
    color: $col-black;
}

.ic-article-status {
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 15px;
    padding: 0.7rem 1rem;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.5px;
}

.text-color-orange{
    color:  $col-orange;
}

.ic-outline-orange-btn {
    &.ic-btn {
        border: 2px solid $col-orange;
        background-color: white;
        color: $col-orange;
        a {
            color: $col-orange;
            text-decoration: initial;
        }
    }
    &.ic-btn:hover {
        color: #fff;
        background-color: $col-orange;
        a {
            color: #fff;
        }
    }
}

.ic-filled-orange-btn {
    &.ic-btn {
        border: 2px solid $col-orange;
        background-color: $col-orange;
        color: white;
        a {
            color: $col-orange;
        }
    }
    &.ic-btn:hover {
        a {
            color: #fff;
        }
    }
}

.modal {
    a {
        &.ic-outline-orange-btn, &.ic-filled-orange-btn {
            text-decoration: initial;
        }
    }
}

.ic-outline-gray-btn {
    &.ic-btn {
        border: 2px solid #898989;
        background-color: white;
        color: #898989;
    }
    &.ic-btn:hover {
        color: #fff;
        background-color: #898989;
    }
}

 a.ic-outline-gray-btn.ic-btn {
        color: #898989;
 }

 a.ic-outline-gray-btn.ic-btn:hover {
        color: #fff;
        background-color: #898989;
 }

.ic-light-grey-btn {
    &.ic-btn {
        border: 1px solid #F0F0F0;
        background-color: #F0F0F0;
        color: #898989;
    }
    &.ic-btn:hover {
        color: #F0F0F0;
        background-color: #898989;
    }
}

.order-1 {
    order: 1;
    @media (max-width: 991px) {
        order: 2;
    }
}

.order-2 {
    order: 2;
    @media (max-width: 991px) {
        order: 1;
    }
}

.square-img {
    width: 110px;
    height: 110px;
    object-fit: cover;
    @media screen and (max-width: 575px) {
        width: 100%;
        height: 246px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1650px) {
        width: 90px;
        height: 90px;
    }
}

.col-5-per-row {
    @media (min-width: 1200px) {
        &.col-xl-2 {
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
        }
    }
}

.img-h-175 {
    width: 100%;
    object-fit: cover;
    height: 229px;
    @media screen and (min-width: 450px) {
        height: 325px;
    }
    @media screen and (min-width: 576px) {
        height: 175px;
    }
}

.img-h-270 {
    width: 100%;
    height:  100%;
    object-fit: cover;
}

.img-h-300 {
    width: 100%;
    object-fit: cover;
    @media (min-width: 576px) {
        height: 175px;
    }
    @media (min-width: 992px) {
        height: 300px;
    }
}

.ic-logo-footer {
    width: 125px;
    @media (min-width: 576px) {
        width: 100px;
    }
}

.side-border {
    @media (min-width: 768px) {
        border-left: 1px solid #e6e5e5;
        border-right: 1px solid #e6e5e5;
    }
}

.vertical-hr {
    height: 70%;
    width: 2px;
    background-color: #e6e5e5;
}

.w-20px {
    width: 20px;
}

.btn-small {
    @media (max-width: 575px) {
        font-size: 12px;
        padding: 0.7rem;
    }
}

.orange {
    color: $col-orange;
}

.border-bottom-ic {
    border-bottom: 1px solid #a4abb1;
}

.border-bottom-orange {
    border-bottom: 4px solid $col-orange;
}

a.orange:hover {
    text-decoration: none;
    opacity: 0.7;
    color: $col-orange;
}

a:hover {
    text-decoration: none;
    opacity: 0.7;
    cursor: pointer;
}

button:hover {
    opacity: 0.7;
}

.user-nav {
    width: 450px;
    height: 100vh;
    position: fixed;
    background-color: #fff;
    top: 0;
    right: 0;
    z-index: 9999;
    overflow-y: auto;
    @media screen and (max-width: 450px) {
        width: 100%;
    }
}

.user-nav-icon {
    width: 35px;
}

.user-nav-header {
    background-color: #e6e5e5;
    height: 275px;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.bg-light-grey {
    background-color: #e6e5e5;
}

.bg-grey {
    background-color: #a2abb2;
}

.light-grey {
    color: $col-light-grey;
}

.border-none {
    border: none;
}

.black,
.black:hover {
    color: $col-black;
}

.grey,
.grey:hover {
    color: $col-grey;
}

.custom-switch .custom-control-label::before {
    width: 47px;
    height: 29px;
    border-radius: 15px;
    background-color: #E8E8E8;
    border: initial;
}

.custom-switch .custom-control-label::after {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    top: 0.36rem;
    left: -2.15rem;
    background-color: #C3C3C3;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    -webkit-transform: translateX(1.1rem);
    transform: translateX(1.1rem);
    background-color: #202124;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #C3C3C3;
    background-color: #C3C3C3;
}

.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #adb5bd;
}

select {
    padding: 5px;
    font-size: 16px;
    line-height: 1;
    border: 0;
    border-radius: 5px;
    height: 34px;
    -webkit-appearance: none;
}

.form-control {
    font-size: 14px;
}

.py-0-7-rem {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
}

.neg-mb-sm-3px {
    @media screen and (min-width: 576px) {
        margin-bottom: -3px;
    }
}

.neg-mb-3px {
   margin-bottom: -3px;
}

input.form-control.is-invalid, input.form-control.is-invalid:focus  {
    border: 2px solid red;
    box-shadow: initial;
}

.mb-sm-15rem {
    margin-bottom: 3rem;
    @media screen and (min-width: 576px) {
        margin-bottom: 15rem;
    }
}

.donation-banner-container {
    max-width: 1167px;
    margin: auto;
}

.donation-banner {
    margin: 20px auto;
    background-color:  #f7f7f7;
    @media screen and (min-width: 576px) {
        margin: 0;
        img.img-fluid {
            height: 222px;
            object-fit: cover;
        }
    }
}

.template-email-confirm-page, .template-password-reset-from-key-page, .template-password-reset-from-key-done-page, .template-verification-sent-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h1 {
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        font-style: initial;
    }

    p {
        font-family: "Montserrat", sans-serif;
        font-size: 14px;
        line-height: 26px;
        @media screen and (min-width: 576px) {
            font-size: 17px;
        }
    }
}

.gradient-fade {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg,rgba(38,37,37,0) 60%,rgba(38,37,37,.6) 80%);
}

.main-header {
    button {
        span {
            a {
                color: $col-black;
            }
             a:hover {
                opacity: initial;

            }
        }
    }
}

.reset-search-button {
    position: absolute;
    right: 5px;
    bottom: 9px;
}

.search-articles-form {
     @media screen and (max-width: 575px) {
              width: 100%;
             }
    .form-group {
        #id_search.form-control {
             padding-left: 40px;
             width: 100%;
             @media screen and (min-width: 576px) {
                 width: 432px;
             }
        }
        input.form-control, select.form-control {
            width: 100%;
            @media screen and (min-width: 576px) {
                width: 432px;
            }
            @media screen and (min-width: 992px) {
                width: 380px;
            }
        }
    }

    #advanced-search-btn {
        cursor: pointer;
        width: 100%;
        @media screen and (min-width: 576px) {
            width: 432px;
            max-width: 432px;
        }
    }

    .search-form-buttons {
        width: 100%;
        @media screen and (min-width: 576px) and (max-width: 991px) {
            width: 432px;
        }
    }

    .auto-complete-text {
        background-color: #f0f0f0;
        padding: 20px;
        border-radius: 5px;
        margin-bottom: 20px;
        display: none;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                margin-bottom: 5px;
                a {
                    font-weight: 600;
                }
            }
        }
    }
}

.search-button {
    position: absolute;
    bottom: 9px;
    left: 5px;
}

 #search-input-label {
    display: none;
}

.advanced-search-container {
    transition:  opacity .3s ease-in, position .3s ease-in;

    .ic-btn.btn-small {
         @media screen and (max-width: 575px) {
              width: 30%;
         }
        &:hover {
            opacity: 75%;
        }
    }

    &.d-none {
        display: initial!important;
        visibility: hidden;
        opacity: 0;
        position: absolute;
    }
    &.d-block {
        display: initial!important;
        visibility: visible;
        opacity: 1;
        position: initial;
    }
}

.search-result-articles {
    position: relative;
    height: 246px;
    @media screen and (min-width: 576px) {
        height: 406px;
    }
    @media screen and (min-width: 768px) {
        height: 505px;
    }
    @media screen and (min-width: 992px) {
        height: 205px;
    }
    @media screen and (min-width: 1336px) {
        height: 213px;
    }
    @media screen and (min-width: 1440px) {
        height: 226px;
    }
    @media screen and (min-width: 1630px) {
        height: 256px;
    }

    .img-fluid {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .bookmark-orange-icons {
        position: absolute;
        top: 15px;
        right: 25px;
    }
    #filled-orange-bookmark-icon {
        display: none;
        margin-right: 2px;
    }
}

.container {
    clear:both;
}

.icon-orange {
    display: none;
}

.user-nav-items.active {
    .icon-orange {
        display: inline-block;
    }
    .icon-black {
        display: none;
    }
    a {
        font-weight: bold;
    }
}

.user-mid-section {
    color: $col-black;
}

.border-right-sidebar {
    @media screen and (min-width: 1200px) {
        border-right: 1px solid #A4ABB1;
    }
}

.mobile-user-sidebar {
    .modal.left .modal-dialog {
        position: fixed;
        margin: auto;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
        @media screen and (max-width: 575px) {
            width: 100%;
        }
    }

    .modal.left .modal-content {
        height: 100%;
        overflow-y: auto;
    }

    .modal.left .modal-body {
        padding: 15px 15px 80px;
    }

    .modal.left.fade .modal-dialog {
        left: -320px;
        -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
        -o-transition: opacity 0.3s linear, left 0.3s ease-out;
        transition: opacity 0.3s linear, left 0.3s ease-out;
    }

    .modal.left.fade.in.show .modal-dialog {
        left: 0;
    }

    .modal-content {
        border-radius: 0;
        border: none;
    }

    .modal-header {
        border-bottom-color: #EEEEEE;
        background-color: #FAFAFA;
    }

}

.newsletter-input-container {
    width: 600px;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.newsletter-and-messages-wrapper {
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.bottom-ad-container {
    width: 100%;
    height: 90px;
    max-width: 728px;
    position: relative;
}

.bottom-ad-image, .long-side-ad-image, .short-side-ad-image, .number-of-articles-sidebar-full-img, .five-articles-in-row-img, .article-category-sidebar-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bottom-ad-title {
    position: absolute;
    top: 35%;
    left: 2%;
    &.bright {
        color: white;
    }
}

.long-side-ad-image-container {
    height: 600px;
    width: 160px;
}

.short-side-ad-image-container {
    height: 250px;
    width: 100%;
    max-width: 300px;
}

.hero-image-container, .one-article-image-container {
    width: 100%;
    height: 229px;
    .img-fluid {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.article-category-sidebar-img-container, .article-search-sidebar-full-img-container, .number-of-articles-sidebar-full-img-container {
    width: 100%;
    height: 165px;
}

#search-page-filter-form, #search-page-filter-form .form-group  {
    flex-direction: row;
    align-items: center;
    @media screen and (max-width: 700px) {
       flex-direction: column;
        align-items: start;
    }
}

#search-page-filter-form .form-group {
    @media screen and (max-width: 700px) {
        width: 100%;
    }
}

#sort-by-select-label {
    width: 80px;
    font-weight: 600;
    margin-bottom: initial;
    margin-right: 10px;
    @media screen and (max-width: 700px) {
        width: initial;
        margin-bottom: 10px;
    }
}

#id_order_by {
    height: initial;
    padding-right: 1.5rem
}

#number-per-page-select-label {
    font-weight: 600;
    margin-bottom: initial;
    margin-right: 10px;
    @media screen and (max-width: 700px) {
        width: 100%;
        margin-bottom: 10px;
    }
}

#number-per-page-select-container {
    width: 70px;
    @media screen and (max-width: 700px) {
        width: 100%;
    }
}

#id_per_page {
    height: initial;
}

#id_per_page + img, #id_order_by + img {
    position: absolute;
    pointer-events: none;
    top: 43%;
    right: 10px;
}

.relative {
    position: relative;
}

.h-20px {
    height: 20px;
}

.top-pagination > hr {
    display: none;
}

.top-pagination > div {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
}

.five-articles-in-row-img-container {
    width: 100%;
    height: 124px;
}

.clickable-container {
    cursor: pointer;
}

.object-fit-100 {
    &.img-fluid {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.img-description-img-container {
    height: 369px;
    .img-fluid {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.full-width-img-container {
    height: 591px;
    .img-fluid {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.two-column-img-container {
    height: 219px;
    .img-fluid {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.user-recent-readings-img-container {
    height: 252px;
    .img-fluid {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

#navbarNavDropdown {
    display: initial !important;
    &.collapse:not(.show) {
        display: none !important;
    }
}

@media screen and (max-width: 350px) {
    .user-nav-body {
        .col-10 {
            padding-right: 0;
            a {
                font-size: 13px;
            }
        }
    }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .user-nav-body {
        .col-2 {
            padding-left: 7px;
            a {
                font-size: 14px;
            }
        }
        .col-10 {
            padding-right: 0;
            a {
                font-size: 13px;
            }
        }
    }
}

@media screen and (min-width: 1400px) and (max-width: 1600px) {
    .user-nav-body {
        .col-2 {
            padding-left: 7px;
            a {
                font-size: 14px;
            }
        }
        .col-10 {
            padding-right: 0;
            a {
                font-size: 14px;
            }
        }
    }
}


nav #id_search {
    @media screen  and (max-width: 399px) {
        display: none !important;
    }
}

.modal {
    a {
        text-decoration: underline;
        color: $col-orange;
        &.ic-btn {
            color: white;
            text-decoration: initial;
        }
        &.ic-btn-orange {
            color: $col-orange;
            text-decoration: initial;
        }
    }
    .modal-body {
        max-height: 80vh;
        overflow-y: auto;
    }
    .modal-header {
        button {
            img {
                margin-right: -5px;
            }
        }
    }
}

#userSidebar {
    &.modal {
        a {
            text-decoration: initial;
        }
        a:active {
            color: black;
        }
    }
}

#input-file-img-wrapper {
    position: relative;
    span {
        position: absolute;
    }
}

.article-to-remove {
    a {
        text-decoration: initial;
    }
    border: 1px solid $col-light-grey;
}

.y-flip {
    transform: rotate(180deg);
}

.like-icon {
    display: none;
}

.share-btn {
    display: none;
}

.pointer {
    cursor: pointer;
}
