/* NIXA SASS
=========================== */
// Made by geniuses at Nixa / www.nixa.ca //

// color variables
$col-orange: #e17b24;
$col-light-grey: #a2abb2;
$col-grey: #455560;
$col-black: #202124;

//fonts
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,700;0,900;1,700;1,900&family=Montserrat:ital,wght@0,400;0,600;0,700;0,800;1,400;1,600;1,700;1,800&display=swap");
@import "fonts.scss";

//Modules

// Components

// Base
@import "_navs/header_nav.scss";
@import "header";
@import "footer";
@import "top_banner";
// Pages
@import "pages/pages";
@import "pages/Home";
@import "pages/user_form";
@import "pages/user_recent_readings";
@import "pages/user_publish_an_article";
@import "pages/user_communities";
@import "pages/user_profile";
@import "pages/single_article";
@import "pages/404";
@import "pages/enable_user";


//utils
@import "utils/spacing";
@import "utils/shared";
@import "utils/buttons";
