
//font-family: 'Merriweather', serif;

body {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 0.89rem;
    line-height: 22px;
    color: $col-grey;
}

h1,
h2,
h4,
h5,
h6,
.article-list-h2,
.nav-link,
.h3-alt,
.h3-alt a,
.like-h4, a.like-h4 {
    font-family: "Merriweather", serif;
    font-weight: 900;
}

h1,
h1 a,
h1 a:hover,
h2,
h2 a,
h2 a:hover,
h3,
h3 a,
h3 a:hover,
h4,
h4 a,
h4 a:hover,
h5,
h6,
.article-list-h1,
.article-list-h2,
.pagination,
.small-extra-bold,
.small-semi-bold,
.navbar-light .navbar-nav .nav-link,
.h3-alt,
.h3-alt a,
.like-h4,
a.like-h4 {
    color: $col-black;
}

h1 {
    font-size: 35px;
    line-height: 45px;
    @media (max-width: 575px) {
        font-size: 25px;
        line-height: 35px;
    }
}

h2, .article-list-h2 {
    font-size: 25px;
    line-height: 35px;
    @media (max-width: 575px) {
        font-size: 22px;
        line-height: 32px;
    }
}

h3, h3 a, .like-h3-a, .article-list-h1, .h2-alt, .h2-alt a {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 25px;
    line-height: 35px;
    letter-spacing: 0.5px;
    @media (max-width: 575px) {
        font-size: 22px;
        line-height: 32px;
    }
}

h4, .h3-alt,
.h3-alt a, .like-h4,
a.like-h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}

h5 {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
}


.extra-bold {
    font-weight: 800;
}

.bold {
    font-weight: 700;
}

.semi-bold {
    font-weight: 600;
}

.small-extra-bold {
    font-weight: 800;
    font-size: 13px;
    line-height: 23px;
}

.small-semi-bold {
    font-weight: 600;
    font-size: 13px;
    line-height: 23px;
}

.nav-link {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}

.author,
.author:hover,
.category-name,
.category-name:hover,
.opinion-author,
.dot,
.publication-date-regular {
    color: $col-orange;
}

.author,
.category-name,
.publication-date,
.publication-date-regular {
    font-size: 13px;
    line-height: 23px;
}

.category-name {
    font-weight: bold;
    text-transform: uppercase;
}

.opinion-author {
    font-size: 14px;
    line-height: 24px;
}

.call-back {
    color: $col-orange;
    font-size: 13px;
    line-height: 23px;
    text-decoration: underline;
}

.publication-date {
    color: $col-light-grey;
    margin-top: 2px;
}

.dot {
    width: 4px;
    height: 4px;
    border-radius: 100%;
    margin-bottom: 2px;
    margin-left: 4px;
    background-color: $col-orange;
}

.arrow-icon {
    margin-left: 6%;
}

.recent-news-categories,
.opinion-featured-category {
    color: $col-orange;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
}

.opinion-message {
    background-color: #f0f0f0;
    font-style: italic;
    font-size: 12px;
    padding: 10px;
}

.opinion-featured-category {
    color: $col-light-grey;
}

.nav-item {
    .top-footer-link {
        font-size: 16px;
        line-height: 26px;
    }
    .bottom-footer-link {
        font-size: 13px;
        line-height: 23px;
        font-weight: 600;
        color: $col-grey;
        font-family: "Montserrat", sans-serif;
        letter-spacing: -0.02rem;
    }
}

li.nav-item a:hover, .article-list-h1:hover,
h3 a:hover {
    opacity: 80%;
}

h3 a,
h3 a:hover {
    text-decoration: none;
}

ul li.nav-item a {
    @media (max-width: 575px) {
        font-size: 18px;
        line-height: 28px;
    }
}

h3 .modal-heading {
    font-weight: 800;
    font-size: 25px;
}

.modal-text {
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    line-height: 27px;
}

.font-15 {
    font-size: 15px;
    line-height: 25px;
}

.font-16 {
    font-size: 16px;
    line-height: 26px;
}

.font-17 {
    font-size: 17px;
    line-height: 27px;
}

.font-18 {
    font-size: 18px;
    line-height: 28px;
}

.font-20 {
    font-size: 20px;
    line-height: 27px;
    @media (max-width: 575px) {
        font-size: 18px;
        line-height: 25px;
    }
}

.font-120 {
    font-size: 120px;
    @media (max-width: 575px) {
        font-size: 90px;
    }
}



.user-nav-footer {
    a {
        @media (max-width: 575px) {
            font-size: 16px;
        }
    }
}

.line-h-30 {
    line-height: 30px;
}

.error-message {
    font-size: 14px!important;
    color: #F22929;
}

.success-message {
    font-size: 14px;
    color: #4F813D;
}

.richtext-editor {
    color: $col-black;
    &.article-img-description > * {
        color: #fff!important;
        @media (max-width: 991px) {
            color: $col-black !important;
        }
        @media (min-width: 992px) {
            a {
                color: #fff;
            }
        }
    }
    p {
        margin-bottom: 0;
        padding-bottom: 1rem;
    }
    h1,h2,h3,h4,h5,h6, .article-list-h1, .article-list-h2, .h3-alt, .h3-alt a, .like-h4,
    a.like-h4 {
        font-family: "Merriweather", serif;
        text-transform: initial;
        font-weight: 700;
    }
    a {
        color: $col-orange;
        text-decoration: underline;
    }
    a:hover {
        color: $col-orange;
        text-decoration: underline;
    }
    h5 {
        font-family: "Montserrat", sans-serif;
        font-weight: normal;
        font-size: 15px;
    }
    h6 {
        font-family: "Montserrat", sans-serif;
        font-weight: normal;
        font-size: 13px;
    }
    blockquote {
        background: #f7f7f7;
        border-left: 4px solid #ccc;
        padding: 0.5em 20px;
    }
    input[type=image] {
        height: auto!important;
        max-width: 100%;
        @media (max-width: 575px) {
            color: $col-black !important;
            margin: initial!important;
        }
    }
    .richtext-image {
        width: 100%;
        height: auto;
        &.full-width {
            margin-bottom: 20px;
        }
        &.left {
            float: left;
            margin-right: 20px;
        }
        &.right {
            float: right;
            margin-left: 20px;
        }
        &.left, &.right {
            @media (max-width: 575px) {
                margin-left: initial;
                margin-right: initial;
                margin-bottom: 20px;
            }
            @media (min-width: 576px) and (max-width: 991px) {
                width: 50%;
                height: auto;
            }
            @media (min-width: 992px) {
                width: initial;
                height: initial;
            }
        }
        iframe {
            max-width: 100%;
            @media (max-width: 500px) {
                height: 200px;
            }
        }
    }
}

.h1-alt {
    font-family: Montserrat,sans-serif;
    font-weight: 700;
    font-style: initial;
}

.like-h3 {
    text-transform: uppercase;
    letter-spacing: .5px;
}

.article-list-h1, .h2-alt, .h2-alt a {
    font-family: Montserrat,sans-serif;
}

.article-list-h2, h3.article-list-h2 a {
    text-transform: initial;
}

h2.category-name, h4.category-name  {
    font-family: Montserrat,sans-serif;
    font-weight: 700;
}
.h3-alt, .h3-alt a {
    text-transform: initial;
}
.sidebar-headings {
    @media screen and (min-width: 1200px) and (max-width: 1650px) {
        word-break: break-word;
        font-size: 18px;
        line-height: 26px;
    }
}
