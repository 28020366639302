body {
    &.template-enable-user-page {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .enable-user-input-container {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }
}
