.article-author-wrap {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.article-bookmark {
    position: absolute;
    top: 5px;
    right: 20px;
    cursor: pointer;
}

.article-body-img-wrap {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: 575px) {
        flex-wrap: wrap;
    }
}

.article-img-description {
    @media (min-width: 991px) {
        position: absolute;
        color: #fff;
        bottom: 1px;
        z-index: 5;
    }
}

.article-img-author {
    font-weight: bold;
    margin-top: 0;
}

.article-author-name{
    display:flex;
    flex-direction:row;
    align-items:flex-start;
    flex-wrap: wrap;
}

.top-navigation-arrow {
    position:absolute;
    right:15px;
    bottom: 50px;
    @media (max-width: 991px) {
        display: none;
    }
}

.top-navigation-arrow-single-article {
   text-align: right;
    @media (max-width: 991px) {
        display: none;
    }
}

.social-icon-container{
     display: block;
    @media (max-width: 991px) {
        display: none;
    }

}
.social-icon-container-mobile{
    padding-left:15px;
    display: none;
    @media (max-width: 991px) {
        display: block;
    }
}

.font-style-initial {
font-style: initial;
}

