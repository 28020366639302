.cancel-button{
    background-color: white;
    @media (max-width: 575px) {
        float: right !important;
    }
}
.submit-button {


}

.upload-images {

}

#cke_id_content, .django-ckeditor-widget {
    width: 100%!important;
    border-radius: 4px;
}

.errorlist {
    list-style: none;
    padding-left: 0;
    margin-top: 10px;
    li {
           font-size: 14px!important;
    color: #F22929;
    }

}

.cke_inner.cke_reset {
    outline: 2px solid #EDEDED;
    border-radius: 4px;
}

.cke_top {
    border-radius: 4px 4px 0 0;
    border-bottom: none !important;
}

.cke_bottom {
    border-radius: 0 0 4px 4px;
    border-top: none !important;
}

.upload-images {
    max-width: 100px;
}

.submit-article-categories-container {
   ul {
       list-style-type: none;
   }
}
