
.main-header-content {
    svg {
        max-width: 100%;
    }
    .row {
        max-width: 900px;
        margin: 0 auto;
    }
    .right-col {
        display: flex;
        img {
            max-width: 90%;
            padding-bottom: 125px;
            z-index: 3;
        }
        .award-badge {
            position: absolute;
            bottom: 0;
            background-color: white;
            border-radius: 5px 5px 0 0;
            padding: 25px 50px;
            text-align: center;
            z-index: 3;
            p {
                margin: 5px;
                padding: 0;
                line-height: initial;
                strong {
                    text-transform: uppercase;
                    font-weight: bold;
                }
            }
            img {
                position: absolute;
                width: 60px;
                margin-left: -27px;
                margin-top: -46px;
                left: 0;
            }
        }
    }
    .left-col {
        color: white;
        flex-direction: column;
        display: flex;
        justify-content: center;
        z-index: 2;
    }
    h1 {
        font-size: 28pt;
        font-family: 'Montserrat-Bold';
    }
    p {
        font-size: 9pt;
    }
    .wrapper-btn {
        .link-nav {
            text-decoration: none;
            text-transform: uppercase;
            border-radius: 0;
            padding: 10px;
            font-size: 9pt;
            font-weight: bold;
        }
        .btn-regular {
            background-color: white;
            color: #3E90D6;
            border: 2px solid white;
        }
        .btn-transparent {
            background-color: transparent;
            color: white;
            border: 2px solid white;
        }
    }
}

@media (max-width: 991.98px) {}

@media (max-width: 767.98px) {
    .header-graphic-element {
        display: none;
    }

    .main-header-content {
        .right-col {
            .award-badge {
                position: initial;
                margin: 45px auto 0;
                img {
                    width: 50px;
                    position: initial;
                    margin-left: 0;
                    margin-top: -60px;
                    padding-bottom: 0;
                }
            }
        }
        .left-col {
            justify-content: center;
            padding: 50px 20px;
            align-items: center;
        }
    }
}

.general-bg-xxx {
    svg {
        width: 100%;
    }
    width: 900px;
    position: absolute;
    top: -425px;
    z-index: 1;
    opacity: 0.2;
}

.general-bg-xxx2 {
    @extend .general-bg-xxx;
    top: -425px;
    z-index: -1;
    opacity: 1;
}

.ic-logo {
    width: 100%;
    max-width: 200px;
    &.ic-logo-modal {
        width: initial;
    }
}

.modal-header {
    border-bottom: initial;
}

.modal-dialog {
    @media (min-width: 576px) {
       max-width: 720px;
    }
}


.modal-button.ic-btn {
    padding: 0.7rem 1.5rem;
}

.input-label {
     font-family: 'Montserrat', sans-serif;
     font-size: 14px;
     color: #A2ABB2;
}

.form-group {
    input.form-control, div.form-control, select.form-control, .input-file-wrapper.form-control {
        border-radius: 4px;
        border: 2px solid #EDEDED;
        height: 40px;
    }
    textarea.form-control {
        border-radius: 4px;
        border: 2px solid #EDEDED;
    }
    .form-control:focus {
        border-color: $col-orange;
        box-shadow: 0 0 0 0.05rem $col-orange;
    }
    input.choose-file[type='file'] {
        opacity: 0;
    }
}

.input-file-wrapper {
    position: relative;
}

.upload-icon {
    position: absolute;
    right: 10px;
    top: 10px;
}

.submit-article-button-container {
    @media screen and (min-width: 992px) and (max-width: 1499px) {
        flex: 0 0 100%;
        max-width: 100%;
        padding-top: 1rem;
    }
    @media screen and (max-width: 575px) {
        flex: 0 0 100%;
        max-width: 100%;
        padding-top: 1rem;
    }
}

.choose-file-placeholder {
    position: absolute;
    color: #6c757d;
    top: 8px;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: $col-orange;
    border: initial;
    box-shadow: initial;
}

.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #adb5bd;
}

.px-64px.modal-button.ic-btn {
    padding-right: 64px;
    padding-left: 64px;
}

.top-row-menu-wrapper {
    //height: 46px;
    //overflow: hidden;
    //.navbar-nav {
    //    flex-direction: row;
    //    .nav-link {
    //        padding-right: 1rem;
    //        padding-left: 1rem;
    //    }
    //}
    flex-wrap: wrap;
    height: 46px;
    overflow: hidden;
}

#vertical-nav {
    display: none;
    margin-top: 20px;
}
