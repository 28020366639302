
.recent-article-img-container {
    @media (min-width: 576px) {
        width: 420px;
        height: 270px;
    }
}
.recent-article-img {
    &.img-fluid {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.article-border {
    border: 1px solid #A4ABB1;
    @media (max-width: 767px) {
        border-top: none;
    }
    @media (min-width: 768px) {
        border-left: none;
    }

}
