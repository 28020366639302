.top-banner-container {
    position: relative;
    img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    .top-banner-text-container {
        position: relative;
        padding: 30px;
        min-height: 374px;
        @media screen and (min-width: 576px) {
            padding: 50px 60px;
        }
        .opacity-layer {
            opacity: 0.7;
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
            top: 0;
            left: 0;
        }

    }
    .col-lg-7 {
        @media screen and (min-width: 992px) {
            flex: 0 0 52.333333%;
        }
    }
    .col-xl-5 {
        @media screen and (min-width: 1200px) {
            flex: 0 0 39.666667%;
        }
    }

    h1, h3 {
        &.top-banner-heading {
            font-family: Montserrat,sans-serif;
            font-weight: 800;
            text-transform: uppercase;
            font-size: 24px;
            position: relative;
            @media screen and (min-width: 576px) {
                font-size: 32px;
            }
        }
    }

    .top-banner-text-wrapper {
        position: relative;
    }
    .ic-btn.btn-small {
        position: relative;
        &.top-banner-left-btn {
            background-color: white;
            border-color: white;
            color: $col-black;
            text-align: center;
        }
        &.top-banner-right-btn {
            color: white;
            text-align: center;
        }

    }
}
