
.user-profile-tabs {
    &.active {
        border-bottom: 4px solid $col-orange;
         font-weight: bold;
    }
}

.nav-tabs {
    &.row {
        border: initial;
    }
}

.user-nav-side {
    a, a:visited, a:hover {
        
        color: $col-black;
    }

    .user-nav-items:hover {
        a {
            font-weight: bold;
            opacity: 100%;
        }
    }
}
