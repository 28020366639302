footer {
    a p, a p:hover {
        color: $col-grey;
    }

    @media screen and (max-width: 575px) {
        .like-h4, a.like-h4 {
            font-size: 16px;
        }
        .like-h4 + ul, a.like-h4 + ul {
            .nav-item .top-footer-link {
                font-size: 14px;
            }
        }
    }
    .bottom-footer-nav-container {
        li.nav-item {
            @media screen and (max-width: 575px) {
                border: 2px solid #ededed;
                width: 100%;
                text-align: center;
                border-radius: 4px;
                margin-bottom: 12px;
            }
        }
    }
}
