.container {
    max-width: initial;
    @media (min-width: 1200px) {
            max-width: 1618px;
    }
}

.px-5rem {
    padding-right: 5rem;
    padding-left: 5rem;
    &.modal-body {
        @media screen and (max-width: 755px) {
            padding-right: 1rem;
            padding-left: 1rem;
        }
    }
}

.px-64px {
    padding-left: 64px;
    padding-right: 64px;
}

.pb-2rem {
    padding-bottom: 2rem;
}

.ic-btn.p-0-7-rem {
    padding: 0.7rem;
}

.w-100-sm {
    @media screen and (max-width: 575px) {
        width: 100%;
    }
}

.w-sm-400px {
    width: 100%;
    @media screen and (min-width: 576px) {
        width: 400px;
    }
}

.mw-25px {
    max-width: 25px;
}

.my-2rem {
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.mw-800px {
    max-width: 800px;
}

.w-222px {
    width: 222px;
}

.mb-sm-2-5 {
     margin-bottom: 1rem;
     @media screen and (min-width: 576px) {
        margin-bottom: 2.5rem;
    }
}

.my-sm-2-5 {
     margin-bottom: 1rem;
     margin-top: 1rem;
     @media screen and (min-width: 576px) {
        margin-bottom: 2.5rem;
        margin-top: 2.5rem;
    }
}

.w-14px {
    width: 14px;
}

.w-min-15px {
    min-width: 15px;
}

.w-18px {
    width: 18px;
}

.w-24px {
    width: 24px;
}

.mr-2px {
    margin-right: 2px;
}

.mx-neg-15px {
    margin-left: -15px;
    margin-right: -15px;
}
