nav {
    font-family: 'Montserrat-Bold';
    //max-width: 900px;
    margin: auto;
    z-index: 4;
}
.btn-info {
    background-color: #3E90D6;
    border-radius: 0;
    font-size: 9pt;
}
li.nav-item {
    a {
        text-decoration: none;
        font-size: 20px;
        color: #202124;
    }
}
.lang {
    text-decoration: none;
    font-size: 10pt;
    color: #424242;
    &:hover {
        text-decoration: none;
        color: #424242;
    }

    border-left: 1px solid black;
    padding: 9px;
}
#menu-toggle {
    width: 100px;
    height: 100px;
    margin: 50px auto;
    position: relative;
    cursor: pointer;
    background: rgba(255,255,255,.4);
    border-radius: 5px;
    &:hover {
        background: rgba(255,255,255,.8);
    }
    #hamburger {
        position:absolute;
        height:100%;
        width:100%;
        span {
            width:60px;
            height:4px;
            position:relative;
            top:24px;
            left:20px;
            margin:10px 0;
            &:nth-child(1){
                transition-delay:.5s;
            }
            &:nth-child(2){
                transition-delay:.625s;
            }
            &:nth-child(3){
                transition-delay:.75s;
            }
        }
    }
    #cross {
        position:absolute;
        height:100%;
        width:100%;
        transform:rotate(45deg);
        span{
            &:nth-child(1){
                height:0%;
                width:4px;
                position:absolute;
                top:10%;
                left:48px;
                transition-delay:0s;
            }
            &:nth-child(2){
                width:0%;
                height:4px;
                position:absolute;
                left:10%;
                top:48px;
                transition-delay:.25s;
            }
        }
    }
}

#menu-toggle.open {
    #hamburger {
        span {
            width:0%;
            &:nth-child(1){
                transition-delay:0s;
            }
            &:nth-child(2){
                transition-delay:.125s;
            }
            &:nth-child(3){
                transition-delay:.25s;
            }
        }
    }
    #cross {
        span {
            &:nth-child(1){
                height:80%;
                transition-delay:.625s;
            }
        }
        span {
            &:nth-child(2){
                width:80%;
                transition-delay:.375s;
            }
        }
    }
}


/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  }

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
        //justify-content: end;
    }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {

}


@media (max-width: 991.98px) {
    .lang {
        width: 100%;
        display: block;
        margin-bottom: 15px;
    }
    .navbar-collapse {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}
.navbar-light .navbar-toggler {
    border: 0;
    padding: 5px;
    &:focus {
        border: 0 !important;
        outline: none !important;
    }
    .fa-times-circle {
        position: absolute;
        right: 22px;
        font-size: 20pt;
        top: 27px;
        display: none;
    }
}
.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
}

.navbar-expand-lg .navbar-nav .nav-link:hover {
    color: $col-orange;
    opacity: 100%;
}
